<template>
	<v-row>
		<v-col cols="12">
			<router-link :to="{
				name: 'PesertaPelatihanDetail',
				params: { id: id_pelatihan },
			}">
				<v-btn small color="warning">
					<v-icon small class="mr-2">
						{{ icons.mdiArrowLeft }}
					</v-icon> Kembali</v-btn>
			</router-link>
		</v-col>

		<v-col cols="6">
			<v-card>
				<v-card-text>

					<v-btn v-if="ujian.nilai_akhir == null" color="primary" @click="mulaiTest()">Mulai Test</v-btn>
					<div v-else>
						<h3> Nilai Pre Test Anda = {{ ujian.nilai_akhir }}</h3>
					</div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="6">
			<v-card>
				<v-card-title primary-title>
					Pre Test
				</v-card-title>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<style scoped>
table tr td {
	vertical-align: top;
}

.pdf {
	width: 100%;
	aspect-ratio: 4 / 3;
}

.pdf,
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose, mdiArrowLeft } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose, mdiArrowLeft
			},
			id_pelatihan: this.$route.params.id_pelatihan,
			pelatihan: {},
			ujian: {},
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getUjian()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		getUjian() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			fmData.append('jenis', 'PRE TEST')
			axiosPostAuth('api/CBT/getUjian', fmData)
				.then(response => {
					this.ujian = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		mulaiTest() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			fmData.append('jenis', 'PRE TEST')
			axiosPostAuth('api/CBT/addUjian', fmData)
				.then(response => {
					if (response.code === 200) {
						this.$router.push({ name: 'PreTest', params: { id_pelatihan: this.id_pelatihan } })
					}
					else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},

	},
}
</script>